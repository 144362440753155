import { useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import {
  MdOutlineAttachMoney,
  MdOutlineClose,
  MdOutlineCurrencyExchange,
  MdOutlineGridView,
  MdOutlineLogout,
  MdOutlineSettings,
} from "react-icons/md";
import { SiGoogledocs } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";
import { GiConfirmed } from "react-icons/gi";
import { IoCalculator } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdReportGmailerrorred } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import "./Sidebar.scss";
import { SidebarContext } from "../../context/SidebarContext";
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from "../../firebase.config";
import { useNavigate } from "react-router-dom";

const logo = require('../../assets/umax-logo.png');

const Sidebar = () => {

  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);

  const navbarRef = useRef(null);

  const location = useLocation();
  const currentPath = location.pathname;

  console.log(currentPath);

  // closing the navbar when clicked outside the sidebar area
  const handleClickOutside = (event) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target) &&
      event.target.className !== "sidebar-oepn-btn"
    ) {
      closeSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clsSidebar = () => {
    const sidebar = document.getElementsByClassName('sidebar')[0]; // Get the first sidebar element

    if (sidebar) { // Check if the sidebar element exists
      sidebar.style.transform = 'translateX(-100%)';
    }
  }

  const [signOut, loading, error] = useSignOut(auth);

  return (
    <nav
      className={`sidebar ${isSidebarOpen ? "sidebar-show" : ""}`}
      ref={navbarRef}
    >
      <div className="sidebar-top">
        <div className="sidebar-brand w-full justify-center">
          <div style={{ marginTop: -5, alignSelf: 'center' }}>
            <img src={logo} alt="" style={{ height: 78, }} />
          </div>
        </div>
        <button className="sidebar-close-btn" onClick={clsSidebar}>
          <MdOutlineClose size={24} />
        </button>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-menu">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/" className={currentPath == '/dashboard' || currentPath == '/' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <MdOutlineGridView size={18} />
                </span>
                <span className="menu-link-text">Dashboard</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/newusers" className={currentPath == '/newusers' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <SiGoogledocs size={20} />
                </span>
                <span className="menu-link-text">New users</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/kycdetails" className={currentPath == '/kycdetails' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <SiGoogledocs size={20} />
                </span>
                <span className="menu-link-text">KYC Details</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/reports" className={currentPath == '/reports' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <TbReportAnalytics size={20} />
                </span>
                <span className="menu-link-text">Reports</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/confirmtrans" className={currentPath == '/confirmtrans' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <GiConfirmed size={20} />
                </span>
                <span className="menu-link-text">Confirm Transactions</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/payments" className={currentPath == '/payments' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <MdOutlineAttachMoney size={20} />
                </span>
                <span className="menu-link-text">Remittance</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/transactions" className={currentPath == '/transactions' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <MdOutlineCurrencyExchange size={18} />
                </span>
                <span className="menu-link-text">Transactions</span>
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/ratecalc" className={currentPath == '/ratecalc' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <IoCalculator size={18} />
                </span>
                <span className="menu-link-text">Rate Calculator</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/customersupport" className={currentPath == '/customersupport' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <MdReportGmailerrorred size={18} />
                </span>
                <span className="menu-link-text">Customer Support</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/assignmanager" className={currentPath == '/assignmanager' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <FaRegUser size={18} />
                </span>
                <span className="menu-link-text">Assign Manager</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/searchuser" className={currentPath == '/searchuser' ? "menu-link active" : "menu-link"}>
                <span className="menu-link-icon">
                  <FaRegUser size={18} />
                </span>
                <span className="menu-link-text">Search User</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="sidebar-menu sidebar-menu2">
          <ul className="menu-list">
            {/* <li className="menu-item">
              <Link to="/" className="menu-link">
                <span className="menu-link-icon">
                  <MdOutlineSettings size={20} />
                </span>
                <span className="menu-link-text">Settings</span>
              </Link>
            </li> */}
            <li className="menu-item"
              onClick={async () => {
                const success = await signOut();
                if (success) {
                  localStorage.removeItem('umaxemployee');
                  alert('You are logged out');
                  navigate('/login')
                }
              }}
            >
              <Link to="/" className="menu-link">
                <span className="menu-link-icon">
                  <MdOutlineLogout size={20} />
                </span>
                <span className="menu-link-text">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav >
  );
};

export default Sidebar;
