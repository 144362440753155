import React from 'react';
import Navbar from '../../common/Navbar';

const companyLogo = require('../../assets/images/162.jpg');

function Profile() {
    return (
        <div>
            <Navbar />

            <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-10 p-4">
                <h5 class="mb-3 text-2xl font-bold tracking-tight text-black-900 dark:text-white">General Information</h5>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Name</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Gaurav Singh</a>
                    </div>
                    <div>
                        <h3 class="text-base  font-semibold text-gray-900 dark:text-white">Company Name</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Atirun Techs Pvt. Ltd.</a>
                    </div>
                    <div>
                        <h3 class="text-base  font-semibold text-gray-900 dark:text-white">Email Id</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">gaurav130202chaudhary@gmail.com</a>
                    </div>
                    <div>
                        <h3 class="text-base  font-semibold text-gray-900 dark:text-white">Phone Number</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">
                            +91 8755356404
                        </a>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Company Type</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Private Limited Company</a>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Billing Address</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">
                            C/O Kalika Prasad, Anupur Mod, Ghatampur,
                            Ghatampur, Kanpur Nagar UP KANPUR
                            UTTAR PRADESH 209206
                        </a>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">GST Number</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">09AADCU0534H1ZG</a>
                    </div>

                </div>
            </div>

            <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5 p-4">
                <h5 class="mb-3 text-2xl font-bold tracking-tight text-black-900 dark:text-white">KYC Details</h5>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">ID Proof</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Pan card</a>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Address Proof</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Aadhar Front</a>
                        <br />
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Aadhar Back</a>
                    </div>
                </div>
            </div>

            <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5 p-4">
                <h5 class="mb-3 text-2xl font-bold tracking-tight text-black-900 dark:text-white">Bank Details</h5>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Account Holder Name</h3>
                        <p class="text-base text-gray-500 dark:text-gray-400">Gaurav Singh</p>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Bank Name</h3>
                        <p class="ext-base text-gray-500 dark:text-gray-400">ICICI Bank Limited</p>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Account Type</h3>
                        <p class="ext-base text-gray-500 dark:text-gray-400">Current</p>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Account Number</h3>
                        <p class="ext-base text-gray-500 dark:text-gray-400">745005000408</p>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">IFSC Code</h3>
                        <p class="ext-base text-gray-500 dark:text-gray-400">ICIC0007450</p>
                    </div>
                    <div>
                        <h3 class="text-base font-semibold text-gray-900 dark:text-white">Cancel Cheque</h3>
                        <a href="#" class="mt-4 text-blue-700 hover:underline dark:text-blue-500">Click to preview</a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Profile