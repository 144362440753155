import { useEffect, useState, createContext, useContext } from "react";
import "./App.scss";
import { ThemeContext } from "./context/ThemeContext";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import BaseLayout from "./layout/BaseLayout";
import { Dashboard, PageNotFound } from "./screens";
import Login from "./screens/auth/Login";
import Reports from "./screens/reports/Reports";
import Transactions from "./screens/transactions/Transactions";
import Ratecalc from "./screens/ratecalc/Ratecalc";
import Profile from "./screens/profile/Profile";
import Kycdetails from "./screens/kycdetails/Kycdetails";
import CustomerSupport from "./screens/customersupport/CustomerSupport";
import Confirmtrans from "./screens/confirmtrans/Confirmtans";
import Payments from "./screens/payments/Payments";
import Searchuser from "./screens/searchuser/Searchuser";
import AddMoney from "./screens/addmoney/addmoney";
import Newusers from "./screens/newusers/Newusers";
import AssignManager from "./screens/assignedmanager/assignmanager"


function App() {

  const [authState, setAuthState] = useState(false);

  document.body.classList.add("dark-mode");
  document.body.classList.add("dark");

  // Handle login success (ideally in Login.js)
  const handleLoginSuccess = () => {
    setAuthState(true);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('umaxemployee');
    if (storedUser) {
      setAuthState(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<BaseLayout />}>
          <Route index path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/newusers" element={<Newusers />} />
          <Route path="/kycdetails" element={<Kycdetails />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/addmoney/:userId" element={<AddMoney />} />
          <Route path="/ratecalc" element={<Ratecalc />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/customersupport" element={<CustomerSupport />} />
          <Route path="/confirmtrans" element={<Confirmtrans />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/searchuser" element={<Searchuser />} />
          <Route path="/assignmanager" element={<AssignManager />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>

  );
}

export default App;