import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase.config';
import { doc, updateDoc, getDoc, setDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore';
import Navbar from '../../common/Navbar';

const AddMoney = () => {
    const { userId } = useParams();
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [currentBalance, setCurrentBalance] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const userWalletRef = doc(db, 'wallets', userId);
                const docSnap = await getDoc(userWalletRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCurrentBalance(data.balance ?? 0); // Initialize to 0 if balance is undefined
                } else {
                    // Create a new document if it doesn't exist
                    await setDoc(userWalletRef, { balance: 0 });
                    setCurrentBalance(0);
                }
            } catch (error) {
                setError('Failed to fetch user wallet.');
                console.error('Error fetching wallet:', error);
            }
        };

        fetchBalance();
    }, [userId]);

    const handleAddMoney = async () => {
        setLoading(true);
        setSuccess(null);
        setError(null);

        if (!amount || isNaN(amount)) {
            setError('Please enter a valid amount.');
            setLoading(false);
            return;
        }

        try {
            const userWalletRef = doc(db, 'wallets', userId);
            const newBalance = currentBalance + parseFloat(amount);
            await updateDoc(userWalletRef, { balance: newBalance });

            // Add transaction record
            const transactionRef = collection(db, 'transactions');
            await addDoc(transactionRef, {
                amount: parseFloat(amount),
                created_at: serverTimestamp(),
                status: 'Success',
                transaction_details: `Payment received for Wallet`,
                transaction_type: 'Credit',
                user_id: userId
            });

            setSuccess(`Successfully added ₹${amount} to the user's wallet.`);
            setAmount('');
            setCurrentBalance(newBalance);
        } catch (error) {
            setError('Failed to add money to the wallet. Please try again.');
            console.error('Error updating wallet:', error);
        }

        setLoading(false);
    };

    return (
        <div>
            <Navbar />
            <div className="bg-white rounded flex w-full mt-5 justify-center dark:bg-gray-800 px-2 py-3">
                <div className="max-w-md w-full">
                    <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">Add Money to Wallet</h2>
                    {success && <p className="text-green-500 mb-4">{success}</p>}
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <div className="mb-4">
                        <label htmlFor="amount" className="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Amount</label>
                        <input
                            type="text"
                            id="amount"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Enter amount"
                        />
                    </div>
                    <button
                        onClick={handleAddMoney}
                        type="button"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Add Money'}
                    </button>
                    <button
                        onClick={() => navigate(-1)}
                        className="mt-4 px-4 py-2 text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddMoney;
