import React, { useEffect, useState } from 'react';
import Navbar from '../../common/Navbar';
import { LuBox } from "react-icons/lu";
import { FaFileDownload } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

function Newusers() {
    const [transactions, setTransactions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [kycDetails, setKycDetails] = useState({
        accountNumber: '',
        accountType: '',
        bankName: '',
        billingAddress: '',
        cityState: '',
        companyEmail: '',
        companyName: '',
        companyPhone: '',
        companyType: '',
        gstNumber: '',
        ifscCode: '',
        kycStatus: '',
        ownerName: '',
        pinCode: '',
        timestamp: new Date(),
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchTransactions = async () => {
        try {
            const q1 = query(collection(db, "kycdetails"));
            const querySnapshot1 = await getDocs(q1);
            let allkycusers = [];
            querySnapshot1.forEach((doc) => {
                allkycusers.push(doc.id);
            });
            console.log("All kyc users", allkycusers);

            const q = query(collection(db, "users"));
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            let allUsers = [];
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                allUsers.push(doc.id);
                orders.push({ ...docData, id: doc.id, kycExist: allkycusers.includes(doc.id) });
            });
            setTransactions(orders);
            console.log(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredList, setFilteredList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedTransactions, setPaginatedTransactions] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = filteredList;
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setPaginatedTransactions(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);

    useEffect(() => {
        const filteredOrders = transactions.filter((item) => {
            // Filter by search query (if applicable)
            if (searchQuery && !item.transaction_details.toLowerCase().includes(searchQuery.toLowerCase())) {
                return false;
            }

            // Filter by date range (if both start and end dates are selected)
            if (startDate && endDate) {
                const itemMoment = moment(item.created_at?.toDate());

                // Ensure itemMoment is valid before comparison
                if (!itemMoment.isValid()) {
                    return false;  // Exclude items with missing or invalid timestamps
                }

                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD');

                // Ensure parsed moments are valid before comparison
                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;  // Exclude if parsing fails
                }

                return (
                    itemMoment >= startDateMoment && itemMoment <= endDateMoment
                );
            }

            // No date range filter or search query applied, return all items
            return true;
        });

        setFilteredList(filteredOrders);
    }, [transactions, searchQuery, startDate, endDate]);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < Math.ceil(transactions.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    }

    const openModal = (userId) => {
        setSelectedUserId(userId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setKycDetails({
            accountNumber: '',
            accountType: '',
            bankName: '',
            billingAddress: '',
            cityState: '',
            companyEmail: '',
            companyName: '',
            companyPhone: '',
            companyType: '',
            gstNumber: '',
            ifscCode: '',
            kycStatus: '',
            ownerName: '',
            pinCode: '',
            timestamp: new Date(),
        });
    };

    const handleChange = (e) => {
        setKycDetails({
            ...kycDetails,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        try {
            if (selectedUserId) {
                const kycRef = doc(db, "kycdetails", selectedUserId);
                await updateDoc(kycRef, kycDetails);
            } else {
                await addDoc(collection(db, "kycdetails"), {
                    ...kycDetails,
                    userId: selectedUserId,
                });
            }
            closeModal();
            fetchTransactions(); // Refresh data
        } catch (error) {
            console.error("Error saving KYC details:", error);
        }
    };

    const handleDelete = async () => {
        try {
            if (selectedUserId) {
                const kycRef = doc(db, "kycdetails", selectedUserId);
                await deleteDoc(kycRef);
                closeModal();
                fetchTransactions(); // Refresh data
            }
        } catch (error) {
            console.error("Error deleting KYC details:", error);
        }
    };

    return (
        <div>
            <Navbar />
            <button onClick={() => openModal(null)} className="btn btn-primary">Add KYC Details</button>

            <div className="mt-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Email</th>
                            <th scope="col" className="px-6 py-3">Phone Number</th>
                            <th scope="col" className="px-6 py-3">KYC STATUS</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedTransactions.map((item) => (
                            <tr key={item.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {item.name}
                                </th>
                                <td className="px-6 py-4">{item.email}</td>
                                <td className="px-6 py-4">{item.phone}</td>
                                <td className={item.kycExist === false ? "px-6 py-4 text-red-500" : "px-6 py-4 text-green-500"}>
                                    {item.kycExist === false ? "Not Completed" : "Completed"}
                                </td>
                                <td className="px-6 py-4">
                                    <button onClick={() => openModal(item.id)} className="btn btn-secondary">Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <nav className="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">1-{transactions.length < pageSize ? transactions.length : pageSize}</span> of <span className="font-semibold text-gray-900 dark:text-white">{transactions.length}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a onClick={handlePrevClick} id="prevButton" className="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Previous</a>
                    </li>
                    <li>
                        <a onClick={handleNextClick} id="nextButton" className="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                    </li>
                </ul>
            </nav>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">{selectedUserId ? 'Edit KYC Details' : 'Add KYC Details'}</h2>
                        <form>
                            {Object.keys(kycDetails).map((key) => (
                                key !== 'timestamp' && (
                                    <div key={key} className="mb-4">
                                        <label className="block text-gray-700">{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
                                        <input
                                            type="text"
                                            name={key}
                                            value={kycDetails[key]}
                                            onChange={handleChange}
                                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                        />
                                    </div>
                                )
                            ))}
                        </form>
                        <button onClick={handleSubmit} className="btn btn-primary">Save</button>
                        {selectedUserId && <button onClick={handleDelete} className="btn btn-danger">Delete</button>}
                        <button onClick={closeModal} className="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Newusers;
