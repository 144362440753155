import React, { useEffect, useState } from 'react'
import Navbar from '../../common/Navbar'
import { LuBox } from "react-icons/lu";
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, doc, updateDoc, serverTimestamp, addDoc, getDoc } from 'firebase/firestore';

function Confirmtrans() {

    const [transactions, setTransactions] = useState([]);

    const fetchTransactions = async () => {
        try {
            const q = query(collection(db, "transactions"), where("transaction_type", "==", "Debit"));
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });

            });
            const filteredTransactions = orders.filter((transaction) => {
                const details = transaction.transaction_details;
                const awbMatch = details.match(/Create order for AWB:(\d+)/);
                return awbMatch !== null;
            }).map((transaction) => {
                const [, awbId] = transaction.transaction_details.match(/Create order for AWB:(\d+)/);
                return { ...transaction, awb_id: awbId };
            });
            setTransactions(filteredTransactions);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [])

    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [filteredList, setFilteredList] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedTransactions, setPaginatedTransactions] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = filteredList.slice().sort((a, b) => b.created_at.seconds * 1000 - a.created_at.seconds * 1000);
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setPaginatedTransactions(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    useEffect(() => {
        const filteredOrders = transactions.filter((item) => {
            // Filter by search query (if applicable)
            if (searchQuery && !item.awb_id.toLowerCase().includes(searchQuery.toLowerCase())) {
                return false;
            }

            // Filter by date range (if both start and end dates are selected)
            if (startDate && endDate) {

                const itemMoment = moment(item.created_at?.toDate());

                // Ensure itemMoment is valid before comparison
                if (!itemMoment.isValid()) {
                    return false;  // Exclude items with missing or invalid timestamps
                }

                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD');

                // Ensure parsed moments are valid before comparison
                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;  // Exclude if parsing fails
                }

                return (
                    itemMoment >= startDateMoment && itemMoment <= endDateMoment
                );
            }

            // No date range filter or search query applied, return all items
            return true;
        });

        setFilteredList(filteredOrders);
    }, [transactions, searchQuery, startDate, endDate]);

    const handleNextClick = () => {
        if (currentPage < Math.ceil(transactions.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    }

    const [actionAwb, setActionAwb] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [orderUser, setOrderUser] = useState('');
    const [umaxshipCharge, setUmaxshipCharge] = useState('');
    const [courierCharge, setCourierCharge] = useState('');
    const [conflictCharge, setConflictCharge] = useState('');
    const [conflictType, setConflictType] = useState('');
    const [conflictReason, setConflictReason] = useState('Captured weight missmatch the declared weight');
    const [orderStatus, setOrderStatus] = useState('');
    const [courierPartner, setCourierPartner] = useState('');

    const handleActionClick = async (item) => {
        setActionAwb(item.awb_id);
        setOrderUser(item.user_id);
        const charges = item.amount.toFixed(2);
        setUmaxshipCharge(charges);
        setTransactionId(item.id);

        const q = query(collection(db, "orders"), where("awb_id", "==", item.awb_id));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
            return null;
        }
        const doc = querySnapshot.docs[0];
        const order_status = doc.data().current_status;
        const courier_partner = doc.data().courier_id;
        setCourierPartner(courier_partner);
        setOrderStatus(order_status);
    }

    useEffect(() => {
        const finalCourierCharge = Number(courierCharge) + (Number(courierCharge) * 0.05)
        if (Number(finalCourierCharge) > Number(umaxshipCharge)) {
            setConflictCharge((Number(finalCourierCharge) - Number(umaxshipCharge)).toFixed(2))
        } else if (Number(finalCourierCharge) < Number(umaxshipCharge)) {
            setConflictCharge((Number(finalCourierCharge) - Number(umaxshipCharge)).toFixed(2))
        } else {
            setConflictCharge((Number(finalCourierCharge) - Number(umaxshipCharge)).toFixed(2))
        }
    }, [courierCharge])

    useEffect(() => {
        if (conflictCharge > 5) {
            setConflictType('todebit')
        } else if (conflictCharge < -5) {
            setConflictType('tocredit')
        } else {
            setConflictType('na')
        }
    }, [conflictCharge])

    const [errorToast, setErrorToast] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('')
    const [loadingTans, setLoadingTrans] = useState(false);

    const verifyTransaction = async () => {
        setLoadingTrans(true);
        try {
            const transactionRef = doc(db, "transactions", transactionId);

            await updateDoc(transactionRef, {
                action_taken: "Yes",
            });
            setActionAwb('');
            setConflictCharge('');
            setToastMessage("Transaction verified successfully");
            setSuccessToast(true);
            fetchTransactions();
            setLoadingTrans(false);
        } catch (error) {
            setActionAwb('');
            setConflictCharge('');
            setToastMessage(error);
            setErrorToast(true);
            fetchTransactions();
            setLoadingTrans(false);
        }
    }

    const creditMoney = async () => {
        setLoadingTrans(true);
        try {
            const transactionRef = doc(db, "transactions", transactionId);
            const walletRef = doc(db, "wallets", orderUser)

            const transactionData = {
                amount: Number(conflictCharge) * -1,
                created_at: serverTimestamp(),
                status: "Success",
                transaction_details: `Money credited for AWB: ${actionAwb}. Reason: ${conflictReason}`,
                transaction_type: "Credit",
                user_id: orderUser
            };

            const docRef = doc(db, "wallets", orderUser);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const walletBalance = data.balance;
                const newBalance = Number(walletBalance) - Number(conflictCharge);
                await addDoc(collection(db, "transactions"), transactionData);
                await updateDoc(transactionRef, {
                    action_taken: "Yes",
                });
                await updateDoc(walletRef, {
                    balance: newBalance,
                });
            }

            setActionAwb('');
            setConflictCharge('');
            setCourierCharge('');
            setToastMessage("Transaction verified successfully");
            setSuccessToast(true);
            fetchTransactions();
            setLoadingTrans(false);
        } catch (error) {
            setActionAwb('');
            setConflictCharge('');
            setCourierCharge('');
            setToastMessage(error);
            setErrorToast(true);
            fetchTransactions();
            setLoadingTrans(false);
        }
    }

    const debitMoney = async () => {
        console.log('Enter in function')
        setLoadingTrans(true);

        const timestamp = serverTimestamp();
        try {
            console.log('Enter in try')
            const transactionRef = doc(db, "transactions", transactionId);
            const walletRef = doc(db, "wallets", orderUser);

            const docRef = doc(db, "wallets", orderUser);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const walletBalance = data.balance;
                console.log(data.balance)
                const newBalance = Number(walletBalance) - Number(conflictCharge);
                await addDoc(collection(db, "transactions"), {
                    amount: Number(conflictCharge),
                    created_at: timestamp,
                    status: "Success",
                    transaction_details: `Money debited for AWB: ${actionAwb}. Reason: ${conflictReason}`,
                    transaction_type: "Debit",
                    user_id: orderUser
                });
                await updateDoc(transactionRef, {
                    action_taken: "Yes",
                });
                await updateDoc(walletRef, {
                    balance: newBalance,
                });
            }

            setLoadingTrans(false);
            setActionAwb('');
            setConflictCharge('');
            setCourierCharge('');
            setToastMessage("Transaction verified successfully");
            setSuccessToast(true);
            fetchTransactions();
        } catch (error) {
            setActionAwb('');
            setConflictCharge('');
            setCourierCharge('');
            setToastMessage(error);
            setErrorToast(true);
            setLoadingTrans(false);
            fetchTransactions();
        }
    }

    return (
        <div>
            <Navbar />
            {
                successToast &&
                <div className='fixed right-10 top-10' style={{ zIndex: 999 }}>
                    <div id="toast-success" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-[#0E9F6E]" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="sr-only">Check icon</span>
                        </div>
                        <div class="ms-3 text-sm text-white font-normal mx-2">{toastMessage}</div>
                        <button onClick={() => setSuccessToast(false)} type="button" class="ms-3 -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                            <span class="sr-only">Close</span>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                </div>
            }

            {
                errorToast &&
                <div className='fixed right-10 top-10' style={{ zIndex: 999 }}>
                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-[#F05252]" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                            </svg>
                            <span class="sr-only">Error icon</span>
                        </div>
                        <div class="ms-3 text-sm font-normal text-white mx-2">{toastMessage}</div>
                        <button onClick={() => setErrorToast(false)} type="button" class=" ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
                            <span class="sr-only">Close</span>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                </div>
            }

            <div className='bg-white rounded flex w-full mt-5 justify-between dark:bg-gray-800 px-2 py-3'>

                <div class="flex items-center max-w-sm mx-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <LuBox size={18} />
                        </div>
                        <input onChange={e => setSearchQuery(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by AWB ID..." required />
                    </div>
                    <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </div>

                <div date-rangepicker class="flex items-center">
                    <span class="mx-4 text-gray-500">from</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input onChange={e => setStartDate(e.target.value)} type="date" name="start" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date start" />
                    </div>
                    <span class="mx-4 text-gray-500">to</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input onChange={e => setEndDate(e.target.value)} name="end" type="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date end" />
                    </div>
                </div>

            </div>

            <div class="mt-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">
                                AWB ID <br />
                                Date & Time
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Amount(Rs.)
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Transaction Details
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Type
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginatedTransactions.map((item) => (
                                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center">
                                        {item.awb_id} <br />
                                        {new Date(item.created_at.seconds * 1000).toLocaleString()}
                                    </th>
                                    <td class="px-6 py-4 text-center">
                                        {(item.amount).toFixed(2)}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.transaction_details}
                                        <br />
                                        By User: {item.user_id}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.transaction_type}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.status}
                                    </td>
                                    <td class="text-center">
                                        <div class="relative me-4">
                                            <button onClick={() => handleActionClick(item)} type="button" class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Take action
                                            </button>
                                            {
                                                item.action_taken === "Yes" ?
                                                    <span style={{ position: 'absolute', top: -10, right: -5 }} class="items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                        Checked
                                                    </span>
                                                    :
                                                    <span style={{ position: 'absolute', top: -10, right: -5 }} class="items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                        No action
                                                    </span>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <nav class="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">1-{transactions.length < pageSize ? transactions.length : pageSize}</span> of <span class="font-semibold text-gray-900 dark:text-white">{transactions.length}</span>
                </span>
                <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a onClick={handlePrevClick} id="prevButton" class="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Previous</a>
                    </li>
                    <li>
                        <a onClick={handleNextClick} id="nextButton" class="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                    </li>
                </ul>
            </nav>

            {
                actionAwb !== '' &&
                <div class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div class="relative p-4 w-full max-w-2xl max-h-full">
                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    Take action
                                </h3>
                                <button onClick={() => setActionAwb('')} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div class="p-4 md:p-5">
                                {
                                    orderStatus === "CANCELLED" &&
                                    <span class="inline-flex items-center mb-2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-2.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                        Enter charged amount by Courier partner as {(umaxshipCharge - (umaxshipCharge * 0.1)).toFixed(2)}
                                    </span>
                                }
                                <div class="grid grid-cols-2 gap-4">
                                    <div>
                                        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Order Status</label>
                                        <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-2.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                            <span class="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
                                            {orderStatus}
                                        </span>
                                    </div>
                                    <div>
                                        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Courier Partner</label>
                                        <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-2.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                            <span class="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
                                            {
                                                courierPartner === 999 ? "Delhivery B2C"
                                                    : courierPartner === 5 ? "Delhivery B2B"
                                                        : courierPartner === 1 || 6 || 8 || 12298 || 4 || 2 || 3 || 12939 || 12938 ? "Xpressbees"
                                                            : ""
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Action for AWB Id</label>
                                        <input type="number" name="actionaAwbId" id="actionaAwbId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            readOnly value={actionAwb}
                                        />
                                    </div>
                                    <div>
                                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charged amount by UmaxShip</label>
                                        <input type="number" name="chargedAmount" id="chargedAmount" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            readOnly value={umaxshipCharge}
                                        />
                                    </div>
                                    <div>
                                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charged amount by Courier partner</label>
                                        <input type="number" name="chargedByCourier" id="chargedByCourier" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:border-blue-500 dark:ring-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            onChange={e => setCourierCharge(e.target.value)} onPaste={e => setCourierCharge(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Conflict amount</label>
                                        <input type="number" name="conflictCharge" id="conflictCharge" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            readOnly value={conflictCharge}
                                        />
                                    </div>
                                    <div>
                                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charged reason</label>
                                        <input type="text" name="conflictReason" id="conflictReason" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            onChange={e => setConflictReason(e.target.value)} value={conflictReason}
                                        />
                                    </div>
                                    <div>
                                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Transaction Type</label>
                                        <input type="text" name="conflictType" id="conflictType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            readOnly value={conflictType === "todebit" ? "Money to be debited from user wallet" : conflictType === "tocredit" ? "Money to be credited in user wallet" : "No action required"}
                                        />
                                    </div>
                                    {
                                        loadingTans ?
                                            <button disabled type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                                <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                                Loading...
                                            </button>
                                            : (
                                                conflictType === 'tocredit' ?
                                                    <button disabled={orderStatus === "CANCELLED"} onClick={creditMoney} type="button" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                        Credit money
                                                    </button>
                                                    : conflictType === 'todebit' ?
                                                        <button disabled={orderStatus === "CANCELLED"} onClick={debitMoney} type="button" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                            Debit money
                                                        </button>
                                                        : conflictType === 'na' ?
                                                            <button onClick={verifyTransaction} type="button" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Mark transaction verified
                                                            </button>
                                                            :
                                                            <button type="button" disabled class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Please fill all the required fileds
                                                            </button>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Confirmtrans