import React, { useEffect, useState } from 'react'
import Navbar from '../../common/Navbar'
import { LuBox } from "react-icons/lu";
import { db, storage } from '../../firebase.config';
import { getDocs, collection, getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, deleteObject, listAll } from 'firebase/storage';
import { saveAs } from 'file-saver';

function Kycdetails() {

    const [kycData, setKycData] = useState([]);

    const fetchKycData = async () => {
        const querySnapshot = await getDocs(collection(db, "kycdetails"));
        const fetchedData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setKycData(fetchedData);
    }

    useEffect(() => {
        fetchKycData();
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [selectedDocData, setSelectedDocData] = useState(null);
    const [username, setUsername] = useState(null)

    const [fileUrls, setFileUrls] = useState({
        addressFront: "",
        addressBack: "",
    });

    const handleTakeActionClick = async (documentId) => {
        setSelectedDocId(documentId);
        setShowModal(true);
    };

    const hideModal = () => {
        setSelectedDocId(null);
        setShowModal(false);
    }

    useEffect(() => {
        if (!selectedDocId) return; // Skip if no document ID is selected

        const fetchDocumentData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const docRef = doc(db, "kycdetails", selectedDocId);
                const usern = doc(db, "users", selectedDocId);

                const docSnap = await getDoc(docRef);
                const userDetail = await getDoc(usern);

                if (docSnap.exists && userDetail.exists) {
                    setSelectedDocData(docSnap.data());
                    setUsername(userDetail.data());
                } else {
                    console.error("Document not found:", selectedDocId);
                }
            } catch (err) {
                console.error("Error fetching document data:", err);
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocumentData();
    }, [selectedDocId]);

    useEffect(() => {
        if (!selectedDocId) return;
        const fetchFileUrls = async () => {
            setIsLoading(true); // Set loading state for files too
            setError(null);

            const addressProofUrls = {}; // Constant to store download URLs

            try {
                const listResult = await listAll(ref(storage, `kycdocs/${selectedDocId}/`)); // Get all files in the folder

                for (const itemRef of listResult.items) {
                    const filename = itemRef.fullPath.split('/').pop(); // Extract filename
                    if (filename.includes('addressProofFront') && isImage(filename)) {
                        addressProofUrls.front = await getDownloadURL(itemRef);
                    } else if (filename.includes('addressProofBack') && isImage(filename)) {
                        addressProofUrls.back = await getDownloadURL(itemRef);
                    }
                }
            } catch (error) {
                console.error("Error fetching address proof URLs:", error);
            }

            console.log("Address Proofs", addressProofUrls);

            // Helper function to check if filename has a common image extension (optional)
            function isImage(filename) {
                const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif']; // Add more extensions if needed
                return imageExtensions.some(ext => filename.endsWith(ext));
            }

            setFileUrls({
                addressFront: addressProofUrls.front,
                addressBack: addressProofUrls.back,
            })

            setIsLoading(false);
        };
        fetchFileUrls();
    }, [selectedDocId])

    const [acceptingKYC, setAcceptingKYC] = useState(false);
    const [rejectingKYC, setRejectingKYC] = useState(false);

    async function approveKyc() {
        try {
            setAcceptingKYC(true);
            const docRef = doc(db, "kycdetails", selectedDocId); // Document reference
            const updateData = {
                kycStatus: "Approved",
            };
            await updateDoc(docRef, updateData);
            fetchKycData();
            setAcceptingKYC(false);
            setSelectedDocId(null);
            setShowModal(false);
        } catch (error) {
            console.error("Error approving KYC:", error);
            setAcceptingKYC(false);
        }
    }

    async function deleteKycData() {
        try {
            setRejectingKYC(true);
            const docRef = doc(db, "kycdetails", selectedDocId); // Document reference
            // Delete document (can be done first or after storage deletion)
            await deleteDoc(docRef);

            const folderRef = ref(storage, `kycdocs/${selectedDocId}`);

            await deleteFolder(folderRef);
            fetchKycData();
            setRejectingKYC(false);
            setSelectedDocId(null);
            setShowModal(false);
        } catch (error) {
            console.error("Error deleting KYC data:", error);
            setRejectingKYC(false);
            // Handle errors appropriately (e.g., show an error message)
        }
    }

    // Helper function for recursive folder deletion (assuming you don't have one)
    async function deleteFolder(folderRef) {
        const listResult = await listAll(folderRef);

        for (const itemRef of listResult.items) {
            // Check if it's a subfolder
            if (itemRef.isDirectory) {
                await deleteFolder(itemRef); // Recursively delete subfolders
            } else {
                await deleteObject(itemRef); // Delete files
            }
        }

        await deleteFolder(folderRef);
        fetchKycData();
        setRejectingKYC(false);
        setSelectedDocId(null);
        setShowModal(false);
    }


    return (
        <div>
            <Navbar />

            <div className='bg-white rounded-lg flex w-full mt-5 justify-between dark:bg-gray-800'>

                <form class="flex items-center max-w-sm mx-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <LuBox size={18} />
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by transaction Id..." required />
                    </div>
                    <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search</span>
                    </button>
                </form>

                <div date-rangepicker class="flex items-center">
                    <span class="mx-4 text-gray-500">from</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input type="date" name="start" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date start" />
                    </div>
                    <span class="mx-4 text-gray-500">to</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input name="end" type="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date end" />
                    </div>
                </div>

            </div>

            <div class="mt-5 relative overflow-x-auto shadow-md sm:rounded-lg dark:bg-gray-900">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Company Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Phone
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Pincode
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            kycData.length > 0 &&
                            kycData.sort((a, b) => b.timestamp - a.timestamp).map((item) => (
                                <tr key={item.id} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.companyName}
                                    </th>
                                    <td class="px-6 py-4">
                                        {item.companyPhone}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.companyEmail}
                                    </td>
                                    <td class="px-6 py-4">
                                        {item.pinCode}
                                    </td>
                                    <td class="px-6 py-4">
                                        <span className={item.kycStatus == 'Approved' ? 'dark:text-green-500' : 'dark:text-red-500'}>
                                            {item.kycStatus ? item.kycStatus : 'Pending'}
                                        </span>
                                    </td>
                                    <td class="px-6 py-4">
                                        <a href="#" onClick={() => handleTakeActionClick(item.id)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                            Take action
                                        </a>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {showModal && selectedDocData &&
                <div id="default-modal" style={{ zIndex: '999' }} tabindex="-1" aria-hidden="true" class="flex overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div class="relative p-4 w-full max-w-4xl max-h-full">

                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    KYC Details
                                </h3>
                                <button onClick={() => hideModal()} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div class="grid grid-cols-3 md:grid-cols-5 gap-4 p-4">
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">User Id</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocId} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Username</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={username.name} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Company Name</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.companyName} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Company Email</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.companyEmail} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Company Phone</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.companyPhone} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Company Type</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.companyType} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Address</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.billingAddress} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Pincode</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.pinCode} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">City & State</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.cityState} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">GST Number</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.gstNumber} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Bank Name</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.bankName} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Account Holder</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.ownerName} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Account Number</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.accountNumber} disabled readonly />
                                    </div>
                                </div>
                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">IFSC Code</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.ifscCode} disabled readonly />
                                    </div>
                                </div>

                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Account Type</label>
                                    <div class="relative mb-4">
                                        <input id="account-id" type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={selectedDocData.accountType} disabled readonly />
                                    </div>
                                </div>

                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Aadhar Front</label>
                                    <div class="relative mb-4">
                                        <a href={fileUrls.addressFront} target='_blank' type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            Open File
                                        </a>
                                    </div>
                                </div>

                                <div>
                                    <label for="account-id" class="text-sm font-medium text-gray-900 dark:text-gray-400 mb-2 block">Aadhar Back</label>
                                    <div class="relative mb-4">
                                        <a href={fileUrls.addressBack} target='_blank' type="text" class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            Open File
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                                {
                                    acceptingKYC ?
                                        <button disabled type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                            <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            Accepting KYC...
                                        </button>
                                        :
                                        <button onClick={() => approveKyc()} data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Approve KYC
                                        </button>
                                }
                                {
                                    rejectingKYC ?
                                        <button disabled type="button" class="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                            <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            Rejecting KYC...
                                        </button>
                                        :
                                        <button onClick={() => deleteKycData()} data-modal-hide="default-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            Reject KYC
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Kycdetails