import React, { useEffect, useState } from 'react'
import Navbar from '../../common/Navbar'
import { IoSearchSharp } from "react-icons/io5";
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, doc, updateDoc, serverTimestamp, addDoc, getDoc } from 'firebase/firestore';


function AssignManager() {

    const [userId, setUserId] = useState('');

    useEffect(() => {
        const currentUser = localStorage.getItem('umaxemployee');
        const jsonData = JSON.parse(currentUser);

        if (jsonData) {
            setUserId(jsonData.uid);
        }
    }, [])

    const [transactions, setTransactions] = useState([]);
    const [managers, setManager] = useState([]);
    const [selectedManager, setSelectedManager] = useState(null);

    const fetchTransactions = async () => {
        try {
            const q = query(collection(db, "users"));
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });
            });
            setTransactions(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    const fetchManager = async () => {
        try {
            const q = query(collection(db, "employees"));
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });
            });
            setManager(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    useEffect(() => {
        fetchTransactions();
        fetchManager();
    }, [])

    const handleActionClick = (user) => {
        setSelectedUser(user);
        setSelectedManager(user.account_manager ? user.account_manager : '');
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [filteredList, setFilteredList] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedTransactions, setPaginatedTransactions] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = filteredList.slice().sort();
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setPaginatedTransactions(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    useEffect(() => {
        const filteredOrders = transactions.filter((item) => {
            // Check if awb_id exists and is a string, and handle search query filtering
            if (searchQuery) {
                const awbId = typeof item.awb_id === 'string' ? item.awb_id.toLowerCase() : '';
                if (!awbId.includes(searchQuery.toLowerCase())) {
                    return false;
                }
            }

            // Handle date range filtering
            if (startDate && endDate) {
                const itemMoment = moment(item.timestamp?.toDate());

                if (!itemMoment.isValid()) {
                    return false;
                }

                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD').endOf('day');

                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;
                }

                return itemMoment.isBetween(startDateMoment, endDateMoment, undefined, '[]');
            }

            return true;
        });

        setFilteredList(filteredOrders);
    }, [transactions, searchQuery, startDate, endDate]);


    const handleNextClick = () => {
        if (currentPage < Math.ceil(transactions.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    }

    const [errorToast, setErrorToast] = useState(false);
    const [successToast, setSuccessToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('')

    const [selectedUser, setSelectedUser] = useState();

    if (userId !== 'Dba8buHqH1coKdGlA9nHmf4NtTI2') {
        return null
    }

    const handleManagerChange = async () => {
        if (selectedUser && selectedManager) {
            try {
                // Find the selected manager's details
                const selectedManagerDetails = managers.find(mgr => mgr.id === selectedManager);

                const updateData = {
                    account_manager: selectedManagerDetails.email,
                    account_manager_name: selectedManagerDetails.name,
                    account_manager_phone_no: selectedManagerDetails.phone_no
                };

                if (selectedManagerDetails) {
                    updateData.account_manager = selectedManagerDetails.email;
                    updateData.account_manager_name = selectedManagerDetails.name;
                    updateData.account_manager_phone_no = selectedManagerDetails.phone_no;
                }

                // Update the user's document in Firestore
                const userRef = doc(db, "users", selectedUser.id);
                await updateDoc(userRef, updateData);

                // Reset state and show success toast
                setSelectedUser(null);
                setSuccessToast(true);
                setToastMessage('Manager updated successfully');
                setTimeout(() => setSuccessToast(false), 3000);
            } catch (error) {
                console.error("Error updating manager:", error);
                setErrorToast(true);
                setToastMessage('Failed to update manager');
                setTimeout(() => setErrorToast(false), 3000);
            }
        }
    };


    return (
        <div>
            <Navbar />
            {
                successToast &&
                <div className='fixed right-10 top-10' style={{ zIndex: 999 }}>
                    <div id="toast-success" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-[#0E9F6E]" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span class="sr-only">Check icon</span>
                        </div>
                        <div class="ms-3 text-sm text-white font-normal mx-2">{toastMessage}</div>
                        <button onClick={() => setSuccessToast(false)} type="button" class="ms-3 -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                            <span class="sr-only">Close</span>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                </div>
            }

            {
                errorToast &&
                <div className='fixed right-10 top-10' style={{ zIndex: 999 }}>
                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-[#F05252]" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                            </svg>
                            <span class="sr-only">Error icon</span>
                        </div>
                        <div class="ms-3 text-sm font-normal text-white mx-2">{toastMessage}</div>
                        <button onClick={() => setErrorToast(false)} type="button" class=" ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
                            <span class="sr-only">Close</span>
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>
                    </div>
                </div>
            }

            <div className='bg-white rounded flex w-full mt-5 justify-between dark:bg-gray-800 px-2 py-3'>
                <div class="flex items-center max-w-sm w-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-50">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <IoSearchSharp size={18} color='white' />
                        </div>
                        <input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} type="text" id="simple-search" class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by Name, Email, ID..." required />
                    </div>
                </div>
            </div>

            <div class="mt-5 relative shadow-md rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400 rounded-lg">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">
                                User ID
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Name
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Account Manager
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginatedTransactions.map((item) => (
                                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <td class="px-6 py-4 text-white">
                                        {item.id}
                                    </td>
                                    <td class="px-6 py-4 text-white">
                                        {item.name}
                                    </td>
                                    <td class="px-6 py-4 text-white">
                                        {item.email}
                                    </td>
                                    <td class="text-center">
                                        <div class="relative me-4">
                                            <button onClick={() => handleActionClick(item)} type="button" class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Take action
                                            </button>
                                            {
                                                item.account_manager ?
                                                    <span style={{ position: 'absolute', top: -10, right: -5 }} class="items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                        {item.account_manager_name}
                                                    </span>
                                                    :
                                                    <span style={{ position: 'absolute', top: -10, right: -5 }} class="items-center bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                                                        No manager
                                                    </span>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <nav class="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">1-{transactions.length < pageSize ? transactions.length : pageSize}</span> of <span class="font-semibold text-gray-900 dark:text-white">{transactions.length}</span>
                </span>
                <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a onClick={handlePrevClick} id="prevButton" class="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Previous</a>
                    </li>
                    <li>
                        <a onClick={handleNextClick} id="nextButton" class="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                    </li>
                </ul>
            </nav>

            {
                selectedUser && (
                    <div class="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
                        <div class="bg-white rounded-lg shadow-lg max-w-md w-full">
                            <div class="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                                <h3 class="text-lg font-medium text-gray-900">Assign Manager</h3>
                                <button onClick={() => setSelectedUser(null)} class="text-gray-500 hover:text-gray-700">
                                    <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="p-6">
                                <div class="mb-4">
                                    <label class="block text-sm font-medium text-gray-700">User Name</label>
                                    <input type="text" value={selectedUser.name} disabled class="w-full bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 mt-1 text-sm text-gray-700" />
                                </div>
                                <div class="mb-4">
                                    <label class="block text-sm font-medium text-gray-700">Select Manager</label>
                                    <select
                                        value={selectedManager}
                                        onChange={(e) => setSelectedManager(e.target.value)}
                                        class="w-full border border-gray-300 rounded-lg px-3 py-2 mt-1 text-sm text-gray-700"
                                    >
                                        <option value="">Select a manager</option>
                                        {managers.map((manager) => (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.email}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div class="px-6 py-4 border-t border-gray-200 flex justify-end">
                                <button onClick={handleManagerChange} class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                                    Save
                                </button>
                                <button onClick={() => setSelectedUser(null)} class="px-4 py-2 text-gray-500 rounded-lg ml-2 hover:text-gray-700">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default AssignManager;
