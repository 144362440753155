import React, { useEffect, useState } from 'react';
import Navbar from "../../common/Navbar";
import { LuBox } from "react-icons/lu";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, } from 'firebase/firestore';
import OrderDetails from '../../common/OrderDetails';

function Reports() {

    const navigate = useNavigate();

    const nonActiveClass = "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
    const activeClass = "bg-blue-700 px-4 py-2 text-sm font-medium text-white border border-gray-200 rounded-lg focus:z-10 dark:bg-blue-500 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";

    const [orderType, setOrderType] = useState(0);

    const [ordersList, setOrdersList] = useState([]);
    const [userId, setUserId] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [phoneQuery, setPhoneQuery] = useState('');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const fetchOrders = async () => {
        try {
            let q = query(collection(db, "orders"));
            if (userId.length > 0) {
                q = query(collection(db, "orders"), where("user_id", "==", userId));
            }
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });
            });
            setOrdersList(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [userId])

    useEffect(() => {
        if (ordersList.length < 1) {
            return;
        }
        ordersList.sort((item1, item2) => {
            const orderId1 = item1.timestamp;
            const orderId2 = item2.timestamp;
            // Descending order comparison
            return orderId2 - orderId1;
        });
    }, [ordersList])

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        setFilteredList(ordersList);
    }, [ordersList])

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortedOrders, setSortedOrders] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = filteredList.slice().sort((a, b) => b.timestamp - a.timestamp); // Sort descending
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setSortedOrders(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);



    useEffect(() => {
        const filteredOrders = ordersList.filter((item) => {

            if (searchQuery && !item.id.toLowerCase().includes(searchQuery.toLowerCase())) {
                return false;
            }


            if (phoneQuery) {
                const awbId = typeof item.awb_id === 'string' ? item.awb_id.toLowerCase() : ''; // Check if awb_id is a string
                if (!awbId.includes(phoneQuery.toLowerCase())) {
                    return false;
                }
            }


            if (startDate && endDate) {
                const itemMoment = moment(item.timestamp?.toDate());

                if (!itemMoment.isValid()) {
                    return false;
                }

                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD').endOf('day');

                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;
                }

                return itemMoment.isBetween(startDateMoment, endDateMoment, undefined, '[]');
            }
            return true;
        });

        setFilteredList(filteredOrders);
    }, [ordersList, searchQuery, startDate, endDate, phoneQuery]);




    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < Math.ceil(ordersList.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    };

    const [fetchOrderId, setFetchOrderId] = useState('');
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const fetchOrderDetails = (order_id) => {
        setFetchOrderId(order_id)
        setShowDetailsModal(true);
    }

    const handleCloseModal = () => {
        setShowDetailsModal(false);
        setFetchOrderId('');
    };

    return (
        <div>
            <Navbar />

            <div className='flex w-full mt-5 justify-between dark:bg-gray-800 rounded-lg p-2'>

                <form class="flex items-center max-w-sm mx-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <IoSearchSharp size={18} color='blue' />
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by User Id" value={userId} onChange={e => setUserId(e.target.value)} required />
                    </div>
                </form>

                <form class="flex items-center max-w-sm mx-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <IoSearchSharp size={18} color='blue' />
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by Order id" value={searchQuery} onChange={handleSearchChange} required />
                    </div>
                </form>

                <form class="flex items-center max-w-sm mx-full">
                    <label for="simple-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <IoSearchSharp size={18} color='blue' />
                        </div>
                        <input type="number" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by AWB ID" value={phoneQuery} onChange={e => setPhoneQuery(e.target.value)} required />
                    </div>
                </form>

                <div date-rangepicker class="flex items-center">
                    <span class="mx-4 text-gray-500">from</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input type="date" name="start" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date start" onChange={e => setStartDate(e.target.value)} />
                    </div>
                    <span class="mx-4 text-gray-500">to</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input name="end" type="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date end" onChange={e => setEndDate(e.target.value)} />
                    </div>
                </div>
            </div>

            <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
                <table class="w-full overflow-y-scroll text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-800 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">
                                Order ID
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Product Details
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Order Value
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Customer Details
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Billable Weight
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedOrders.map((item) => (
                                <tr key={item.id} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td onClick={() => fetchOrderDetails(item.id)} class="px-6 py-4 text-white text-center cursor-pointer">
                                        {item.id}
                                        <br />
                                        <span className='text-blue-500'>
                                            {item?.awb_id ? item.awb_id : ''}
                                        </span>
                                    </td>
                                    <td class="px-6 py-4 text-white text-center">
                                        <span className='text-s text-gray-300'>
                                            {item.order_type === "B2B" ?
                                                JSON.parse(item.data).product_desc
                                                :
                                                JSON.parse(item.data)?.order_items?.map((orderItem) => (
                                                    <span key={orderItem.sku}>{orderItem.name} - {orderItem.units} units</span>
                                                ))
                                            }
                                        </span>
                                    </td>
                                    <td class="px-6 py-4 text-gray-300 text-center">
                                        {JSON.parse(item.data).sub_total}
                                        <br />
                                        {JSON.parse(item.data).payment_method}
                                    </td>
                                    <td class="px-6 py-4 text-gray-300 text-center">
                                        {JSON.parse(item.data).billing_customer_name} {JSON.parse(item.data).billing_last_name}
                                        <br />
                                        {JSON.parse(item.data).billing_phone}
                                    </td>
                                    <td class="px-6 py-4 text-gray-300 text-center">
                                        {JSON.parse(item.data).weight} KG
                                    </td>
                                    <td class="px-6 py-4 text-gray-300 text-center">
                                        {item.current_status === "DELIVERED" ?
                                            <span className='dark:bg-green-700 p-1 rounded text-white'>
                                                {item.current_status}
                                            </span>
                                            : item.current_status === "CANCELLED" ?
                                                <span className='dark:bg-red-700 p-1 rounded text-white'>
                                                    {item.current_status}
                                                </span>
                                                :
                                                <span className='dark:bg-yellow-700 p-1 rounded text-white'>
                                                    {item.current_status}
                                                </span>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {
                showDetailsModal &&
                <OrderDetails order_id={fetchOrderId} onClose={handleCloseModal} />
            }

            <nav class="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">1-{ordersList.length < pageSize ? ordersList.length : pageSize}</span> of <span class="font-semibold text-gray-900 dark:text-white">{ordersList.length}</span>
                </span>
                <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a onClick={handlePrevClick} id="prevButton" class="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Previous</a>
                    </li>
                    <li>
                        <a onClick={handleNextClick} id="nextButton" class="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                    </li>
                </ul>
            </nav>

        </div >
    )
}

export default Reports