import React, { useEffect, useState } from 'react'
import Navbar from '../../common/Navbar'
import { LuBox } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, getDoc, doc, updateDoc } from 'firebase/firestore';

function Payments() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        const currentUser = localStorage.getItem('umaxemployee');
        const jsonData = JSON.parse(currentUser);
        if (jsonData) {
            setUserId(jsonData.uid);
        }
    }, []);

    useEffect(() => {
        const user = window.localStorage.getItem('umaxemployee');
        if (!user) {
            navigate('/login/');
        }
    }, [navigate]);

    const fetchTransactions = async () => {
        try {
            const q = query(collection(db, "remittances"));
            const querySnapshot = await getDocs(q);
            let orders = []; // Initialize empty array to store valid orders
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });

            });
            setTransactions(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [userId]);

    useEffect(() => {
        if (transactions.length > 0) {
            transactions.sort((item1, item2) => {
                const orderId1 = item1.timestamp.seconds * 1000;
                const orderId2 = item2.timestamp.seconds * 1000;
                return orderId2 - orderId1;
            });
        }
    }, [transactions]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedTransactions, setPaginatedTransactions] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = transactions.slice().sort((a, b) => b.timestamp.seconds * 1000 - a.timestamp.seconds * 1000);
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setPaginatedTransactions(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < Math.ceil(transactions.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    }

    useEffect(() => {
        const filteredOrders = transactions.filter((item) => {
            // Filter by search query (if applicable)
            if (searchQuery && !item.order_id.toLowerCase().includes(searchQuery.toLowerCase())) {
                return false;
            }

            if (startDate && endDate) {

                const itemMoment = moment(item.timestamp?.toDate());

                if (!itemMoment.isValid()) {
                    return false;
                }

                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD');

                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;
                }

                return itemMoment >= startDateMoment && itemMoment <= endDateMoment;
            }

            return true;
        });

        setFilteredList(filteredOrders);
    }, [transactions, searchQuery, startDate, endDate]);

    const [selectedId, setSelectedId] = useState('');
    const [selectedUserid, setSelectedUserid] = useState('');
    const [orderDeliveryDate, setOrderDeliveryDate] = useState('');
    const [slectedOrderStatus, setSlectedOrderStatus] = useState('');
    const [orderAmount, setOrderAmount] = useState('');
    const [earlyCod, setEarlyCod] = useState('');
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState()

    const handleSelectTrans = async (item) => {
        setLoading(true);
        setSelectedId(item.id);
        setSelectedUserid(item.user_id);
        setOrderDeliveryDate(item.delivery_date);
        setSlectedOrderStatus(item.remark);
        setOrderAmount(item.cod_amount);
        const docRef = doc(db, "kycdetails", item.user_id);
        const docSnap = await getDoc(docRef);
        const docData = docSnap.data();
        const docRef2 = doc(db, "users", item.user_id);
        const docSnap2 = await getDoc(docRef2);
        const docData2 = docSnap2.data();
        setUserDetails({ ...docData, docData2 });
        console.log({ ...docData, docData2 });
        setLoading(false);
    }

    const [settlementAmount, setSettlementAmount] = useState();

    useEffect(() => {
        if (orderAmount === '') return;
        if (userDetails?.docData2.earlyCod === 'Standard') {
            setSettlementAmount(Number(orderAmount) - (Number(orderAmount) * 0.02))
        } else if (userDetails?.docData2.earlyCod === 'Pro') {
            setSettlementAmount(Number(orderAmount) - (Number(orderAmount) * 0.0175))
        } else if (userDetails?.docData2.earlyCod === 'Premium') {
            setSettlementAmount(Number(orderAmount) - (Number(orderAmount) * 0.01))
        } else if (userDetails?.docData2.earlyCod === 'Enterprise') {
            setSettlementAmount(Number(orderAmount) - (Number(orderAmount) * 0.0075))
        } else {
            setSettlementAmount(Number(orderAmount) - (Number(orderAmount) * 0.02))
        }
    }, [orderAmount])

    const closeModal = () => {
        setSelectedId('');
        setSelectedUserid('');
        setOrderDeliveryDate('');
        setOrderAmount('');
    }

    function todayDate() {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        // Format the date as a string
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
    }

    const settleAmount = async () => {
        const docRef = doc(db, "remittances", selectedId);
        await updateDoc(docRef, {
            status: "Paid",
            deduction: Number(orderAmount) - settlementAmount,
            transfered_amount: settlementAmount,
            remark: `COD amount settled on ${todayDate()}`
        });
        fetchTransactions();
        closeModal();
    }

    return (
        <div>
            <Navbar />

            <div className='bg-white rounded flex w-full mt-5 justify-between dark:bg-gray-800 px-2 py-3'>
                <form className="flex items-center max-w-sm mx-full">
                    <label htmlFor="simple-search" className="sr-only">Search</label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <LuBox size={18} />
                        </div>
                        <input onChange={e => setSearchQuery(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by order id..." required />
                    </div>
                    <button type="submit" className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span className="sr-only">Search</span>
                    </button>
                </form>

                <div date-rangepicker className="flex items-center">
                    <span className="mx-4 text-gray-500">from</span>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input
                            onChange={e => setStartDate(e.target.value)}
                            type="date"
                            name="start"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date start"
                        />
                    </div>
                    <span className="mx-4 text-gray-500">to</span>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input
                            onChange={e => setEndDate(e.target.value)}
                            name="end"
                            type="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date end"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">
                                Order Date
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Order ID Ref
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                COD Amount (Rs.)
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Order Status
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Early COD
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Amount Settled
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paginatedTransactions.map((item) => (
                                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {new Date(item.timestamp.seconds * 1000).toLocaleString()}
                                    </th>
                                    <td class="px-6 py-4 text-center">
                                        {item.order_id}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.cod_amount}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.remark}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.early_cod}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.status === "Not paid" ?
                                            <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-orange-900 dark:text-orange-300">
                                                <span class="w-2 h-2 me-1 bg-orange-500 rounded-full"></span>
                                                Pending
                                            </span>
                                            :
                                            <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                                <span class="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
                                                Paid
                                            </span>
                                        }
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        {item.transfered_amount}
                                    </td>
                                    <td class="px-6 py-4 text-center">
                                        <button onClick={() => handleSelectTrans(item)} type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Take action
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <nav className="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * pageSize + 1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * pageSize, filteredList.length)}</span> of <span className="font-semibold text-gray-900 dark:text-white">{filteredList.length}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a
                            onClick={handlePrevClick}
                            className={`flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            Previous
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={handleNextClick}
                            className={`flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage >= Math.ceil(filteredList.length / pageSize) ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            Next
                        </a>
                    </li>
                </ul>
            </nav>

            {
                selectedId !== "" &&
                <div id="default-modal" class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div class="relative p-4 w-full max-w-2xl max-h-full">
                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    Remmitance Details
                                </h3>
                                <button onClick={closeModal} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>

                            {
                                loading ?
                                    <div class="flex items-center justify-center w-full h-56 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                        <div class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">loading...</div>
                                    </div>
                                    :
                                    <div class="p-4 md:p-5 space-y-4">
                                        <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                                            <span class="font-bold dark:text-white">COD Amount:</span> Rs. {orderAmount}
                                        </p>
                                        <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                                            <span class="font-bold dark:text-white">Amount to be settled:</span> Rs. {settlementAmount}
                                        </p>
                                        <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                                            <span class="font-bold dark:text-white">Order Status:</span>{slectedOrderStatus}
                                        </p>
                                        <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                                            <span class="font-bold dark:text-white">Early COD: </span>{userDetails.docData2.earlyCod ? userDetails.docData2.earlyCod : 'Standard'} Plan
                                        </p>
                                        <p class="text-base leading-relaxed text-gray-500 dark:text-white">
                                            <span class="font-bold dark:text-white">Bank Details</span>
                                            <br />
                                            <span class="dark:text-gray-300">Contact:</span> {userDetails.companyPhone}
                                            <br />
                                            <span class="dark:text-gray-300">Company Name:</span> {userDetails.companyName}
                                            <br />
                                            <span class="dark:text-gray-300">Account Holder Name:</span> {userDetails.ownerName}
                                            <br />
                                            <span class="dark:text-gray-300">Account Number: </span> {userDetails.accountNumber}
                                            <br />
                                            <span class="dark:text-gray-300">Account Type: </span> {userDetails.accountType}
                                            <br />
                                            <span class="dark:text-gray-300">IFSC Code: </span> {userDetails.ifscCode}
                                            <br />
                                            <span class="dark:text-gray-300">Bank Name: </span> {userDetails.bankName}
                                        </p>
                                    </div>
                            }

                            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button onClick={settleAmount} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Amount settled
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Payments;
